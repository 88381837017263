import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#FF5544',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#FF5544',
            contrastText: '#ffffff',
        },
        background: {
            default: '#151926',
            paper: '#151926'
        },
    }
});

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#151926',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#FF5544',
            contrastText: '#ffffff',
        },
        background: {
            default: '#F5F5F5'
        },
    }
});