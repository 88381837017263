import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import useRemoteConfig from "../../../../hooks/backend/RemoteConfig";
import CachedIcon from '@mui/icons-material/Cached';
import ProjectFormTemplate from "./ProjectFormTemplate";
import { useForm } from "react-hook-form";





export default function GeneralSettingsForm(props) {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const { remoteConfig, updateRemoteConfig, UiStream, components } = useRemoteConfig();
    const [loading, setLoading] = useState(true);

    // form
    const [_, setWebsiteVersion] = useState(0);
    const [configFetchInterval, setConfigFetchInterval] = useState(0);
    const [primaryColor, setPrimaryColor] = useState("#000000");
    const [backgroundColor, setBackgroundColor] = useState("#000000");
    const [initialLink, setInitialLink] = useState("https://webvify.app");
    const [splashDelay, setSplashDelay] = useState(0);

    useEffect(() => {
        if (remoteConfig && remoteConfig.preferences) {
            setWebsiteVersion(remoteConfig.website_version);
            setConfigFetchInterval(remoteConfig.config_fetch_interval);
            setPrimaryColor(remoteConfig.primary_color);
            setBackgroundColor(remoteConfig.background_color);
            setInitialLink(remoteConfig.initial_link);
            setSplashDelay(remoteConfig.preferences.splash_delay);
            setLoading(false);
        }
    }, [remoteConfig]);

    useEffect(() => {
        if (UiStream.value && UiStream.value.includes("loading")) {
            setLoading(UiStream.value === "start_loading");
        }
    }, [UiStream]);



    const handleSaveButton = () => {
        var config = { ...remoteConfig };
        config.config_fetch_interval = configFetchInterval * 1;
        config.primary_color = primaryColor;
        config.background_color = backgroundColor;
        config.initial_link = initialLink;
        config.preferences.splash_delay = splashDelay * 1;
        setTimeout(() => updateRemoteConfig(config), 500);
    };

    const handleClearWebsiteCacheButton = () => {
        var config = { ...remoteConfig };
        config.website_version = config.website_version + 1;
        setTimeout(() => updateRemoteConfig(config), 500);
    }

    return (
        <ProjectFormTemplate
            loading={loading}
            infoLink="https://google.com"
            rcComponents={components}
            footer={
                <Grid item>
                    <Button
                        onClick={handleClearWebsiteCacheButton}
                        startIcon={<CachedIcon />}
                        color="primary"
                        variant="contained"
                    >
                        Clear Website Cache
                    </Button>
                </Grid>
            }
            handleSaveButton={handleSubmit(handleSaveButton)}>
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        error={errors?.initialLink}
                        helperText={errors?.initialLink?.message ?? "This will be app initial link."}
                        id="initial_link"
                        label="Initial Link"
                        name="initial_link"
                        value={initialLink}
                        required
                        {...register("initialLink", { required: 'This field is required' })}
                        type="url"
                        autoComplete="text"
                        autoFocus
                        onChange={(e) => setInitialLink(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        helperText={"Select your website's primary color in here"}
                        name="primary_color"
                        color='primary'
                        label="Primary Color"
                        value={primaryColor}
                        type="color"
                        id="primary_color"
                        onChange={(e) => setPrimaryColor(e.target.value)}
                        autoComplete="current-password"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="background_color"
                        color='primary'
                        label="Background Color"
                        value={backgroundColor}
                        type="color"
                        id="background_color"
                        onChange={(e) => setBackgroundColor(e.target.value)}
                        autoComplete="background_color"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        name="splash_delay"
                        error={errors?.splashDelay}
                        helperText={errors?.splashDelay?.message ?? "1000 is one second."}
                        color='primary'
                        label="Splash Delay"
                        value={splashDelay}
                        {...register("splashDelay", { min: { value: 0, message: "This fields must more than zero" } })}
                        type="number"
                        id="splash_delay"
                        onChange={(e) => setSplashDelay(e.target.value)}
                    />
                </Grid>
            </Grid>
        </ProjectFormTemplate >


    );
}

/*
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        fullWidth
                        error={errors?.configFetchInterval}
                        helperText={errors?.configFetchInterval?.message ?? "60 is one minute. Users will fetch the latest configuration props based on this time."}
                        name="config_fetch_intervak"
                        color='primary'
                        label="Config Fetch Interval"
                        value={configFetchInterval}
                        {...register("configFetchInterval", { min: { value: 0, message: "This fields must more than zero" } })}
                        type="number"
                        id="config_fetch_intervak"
                        onChange={(e) => setConfigFetchInterval(e.target.value)}
                    />
                </Grid>
*/